@import "../../../../../palette.scss";
@import "./DishesFilter/dishesFilter.scss";
@import "./PreMenu/premenu.scss";

.restaurant-menu-wrapper {
  height: 100%;
  .restaurant-header-wrapper {
    position: relative;
    height: 25%;
    .back-button {
      position: absolute;
      z-index: 2;
      padding: 4px 8px;
      button {
        font-size: 12px;
        padding: 0px 8px;
        border-radius: 50px;
      }
    }

    @mixin restaurant-logo-base {
      position: absolute;
      min-width: 55%;
      height: 60%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      top: 2.5vh;
      left: 50%;
      transform: translateX(-50%);
      z-index: 1;
    }

    @mixin header-background($vertical: 3) {
      content: " ";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      opacity: 0.3;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      @if $vertical == 3 {
        background-image: url("../../../../../static/restaurant.jpg");
      }
      @if $vertical == 4 {
        background-image: url("../../../../../static/deal.jpg");
      }
    }

    .restaurant-logo-wrapper {
      &::before {
        @include header-background(3);
      }
      .restaurant-logo {
        @include restaurant-logo-base();
      }
      @include media(">=phone", "<=desktop", "landscape") {
        .restaurant-logo {
          height: 13vh;
        }
      }
    }

    .business-logo-wrapper {
      &::before {
        @include header-background(4);
      }
      .restaurant-logo {
        @include restaurant-logo-base();
      }
      @include media(">=phone", "<=desktop", "landscape") {
        .restaurant-logo {
          height: 13vh;
        }
      }
    }

    .restaurant-translation {
      width: 22px;
      position: absolute;
      right: 12px;
      top: 12px;
    }
  }

  .restaurant-menu-body-wrapper {
    height: calc(75% + 25px);
    .restaurant-menu-body-card {
      /*
        Clean ant design mobile borders for card
      */
      
      &.am-card {
        &::before {
          content: none;
        }
        &::after {
          content: none;
        }
        .am-card-body {
          &::before {
            content: none;
          }
          &::after {
            content: none;
          }
        }
      }
      border-radius: 25px 25px 0px 0px !important;
      margin-top: -25px;
      height: 100%;
      .top-menu {
        font-size: 0.8rem !important;
      }
      .category-filters {
        width: 100%;
        height: 10%;
        min-height: 58px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        &::before {
          content: "";
          border-top: solid 1px RGBA(51, 51, 51, 0.07);
          width: 80%;
          height: 1px;
        }

        &::after {
          content: "";
          border-top: solid 1px RGBA(51, 51, 51, 0.07);
          width: 80%;
          height: 1px;
        }

        .selected {
          background-color: #333;
          border-radius: 15px;
          padding: 10px;
          a {
            color: $pure-white;
          }
        }

        &__menu-item {
          background: transparent;
          display: flex;
          justify-content: left;
          align-items: center;
          height: 50px;
          white-space: nowrap;
          margin-right: 16px;
          a {
            font-size: 0.9rem;
            font-weight: bold;
            color: #333;
            user-drag: none; 
            user-select: none;
            -moz-user-select: none;
            -webkit-user-drag: none;
            -webkit-user-select: none;
            -ms-user-select: none;
            -webkit-touch-callout: none;
            -khtml-user-select: none;
          }
        }

        &__menu-item:last-child {
          margin-left: 0px !important;
        }

        &__container {
          width: 90vw;
          height: 100px;
          direction: ltr;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          overflow: hidden;
          overflow-x: auto;
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
        }

        /* Hide scrollbar for Chrome, Safari and Opera */
        &__container::-webkit-scrollbar {
          display: none;
        }

        &__container.active {
          cursor: -webkit-grabbing;
          cursor: grabbing;
        }

        &__container.not-scrollable {
          justify-content: center;
        }

        &__wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .dishes-wrapper {
      /* 
        MAX-HEIGHT NEEDS TO BE ADAPTED EVERYTIME A NEW MENU/LINE IS ADDED ON TOP
        OF 'dishes-wrapper' IN ORDER TO PREVENT SCROLL ON MAIN BODY/CONTAINER
      */
      max-height: 79%;
      overflow-y: auto;
      scroll-behavior: smooth;

      .dish-container {
        padding-bottom: 60px !important;
        width: 100%;
        .dish {
          border-radius: 15px;
          margin: 0px 8px;
          box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.05);
          -webkit-box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.05);
          -moz-box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.05);
          align-items: stretch;
          min-height: 47px;

          .dish-thumb {
            height: 100%;
            min-height: 51px;
            background-size: cover;
            background-position: center;
          }

          .dish-icon-placeholder {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            opacity: 0.8;
            min-height: 40px;
            max-height: 100%;
          }

          @include media(">=tablet") {
            .dish-thumb {
              max-height: 150px;
            }
          }

          .dish-body {
            flex: 1 0 55%;

            .dish-title {
              font-weight: bold;
              font-size: 1rem;
              &.expanded {
                margin-bottom: 12px;
              }
            }

            .dish-price {
              font-size: 0.9rem;
              font-weight: bold;
              margin: 2px 0px;
              margin-right: 3px;
              .single-price {
                background-color: #ededed;
                border-radius: 6px;
                div {
                  text-align: center;
                }
              }
              .multi-price {
                background-color: #ededed;
                border-radius: 6px;
                margin: 3px 2px;
                div {
                  text-align: center;
                  padding: 0px 2px;
                }
              }
              &.expanded {
                white-space: pre-line;
              }
            }

            .dish-description {
              width: 85%;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }

            @include media(">=tablet") {
              .dish-description {
                display: -webkit-box;
                -webkit-line-clamp: 4;
                -webkit-box-orient: vertical;
                white-space: normal;
                text-overflow: ellipsis;
              }
            }

            .dish-carousel-img {
              width: 100%;
              height: 205px;
              vertical-align: top;
              border-radius: 15px;
              object-fit: cover;
            }

            @include media(">=tablet") {
              .dish-carousel-img {
                height: 350px;
              }
            }

            @include media(">=tablet") {
              .dish-carousel-wrapper {
                position: relative;
                max-width: 33vw !important;
                margin-left: 50%;
                transform: translate(-50%);
              }
            }

            .allergens-wrapper {
              max-width: 100%;
              .allergens {
                white-space: nowrap;
                font-size: 0.8rem;
                margin-left: 0px;
                flex-basis: 100%;
              }
            }
          }
        }
      }
    }

    @include media(">=phone", "<=desktop", "landscape") {
      .dishes-wrapper {
        max-height: 63%;
      }
    }
  }
}
