@import '../../palette.scss';

.layout-form {
  &__title {
    &__count {
      text-align: right;
    }
  }

  &__textarea {
    height: 120px !important;
    resize: none;

    &__count {
      text-align: right;
    }
  }
}

.add-link-actions {
  display: flex;
  gap: 0 16px;
  justify-content: right;
}

.preview-wrapper {
  overflow: auto;
  flex-grow: 1;
  padding-bottom: 16px;
}

.preview {
  position: sticky;
  top: 88px;
  width: 375px;
  height: 812px;
  background-color: $neumorphic-background-color;
  border-radius: 40px;
  border: 6px solid #222;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  &__notch {
    position: absolute;
    top: 0;
    left: 50%;
    width: 280px;
    height: 42px;
    background-color: #222;
    border-radius: 0 0 28px 28px;
    transform: translateX(-50%);
    z-index: 10;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: -7 * 1px;
      width: 7 * 2px;
      height: 7 * 1px;
      background-size: 50% 100%;
      background-repeat: no-repeat;
      background-image: radial-gradient(circle at 0 100%,
          transparent 7 - 1px,
          #222 7 * 1px);
    }

    &::after {
      left: 100%;
      margin-right: corner-sizepx;
      background-image: radial-gradient(circle at 100% 100%,
          transparent 7 - 1px,
          #222 7 * 1px);
    }
  }

  &__header {
    text-align: center;

    .preview__logo {
      position: relative;
      height: 150px;
      width: 100%;

      .logo-public {
        position: absolute;
        min-width: 55%;
        height: 65%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        top: 2.5vh;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;
      }
    }

    &__description {
      text-align: center;
    }
  }

  &__icons {
    display: flex;
    gap: 8px;
    justify-content: center;
  }

  &__links {
    margin-top: 45px;
    padding: 0 16px;
    .neumorphic {
      width: 100%;
      border-radius: 20px;
      background: $neumorphic-background-color;
      box-shadow: -5px 5px 10px #e7e7ed,
        5px -5px 10px #ffffff;

      button {
        &.neumorphic-button {
          border: 0px;
          width: 100% !important;
          background-color: transparent;
          border-radius: inherit;
          height: 50px;
          color: rgba(0, 0, 0, 0.85);
        }
      }

      &__pressed {
        border-radius: 20px;
        background: $neumorphic-background-color;
        box-shadow: inset -5px 5px 10px #e7e7ed,
          inset 5px -5px 10px #ffffff;
      }
    }
  }
}

.upload-button-wrapper {
  position: relative;
  .upload-label {
    position: absolute;
    left: calc(0px - 8px);
    bottom: calc(0px - 8px);
    background: rgb(85, 129, 192);
    font-size: 12px;
    width: calc(100% + 16px);
    color: #fff;
    border-radius: 4px 4px 0px 0px;
  }
}
