@import '../../palette.scss';

.password-reset__wrapper {
  background-color: $background-color;
  height: 100vh;

  .error {
    color: $error;
    font-weight: bold;
  }

  .content {
    min-height: 100vh;

    .logo {
      width: 150px !important;
    }
  }
}