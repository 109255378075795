@import '../../palette.scss';
@import '../include-media.scss';

@mixin min-height-manager {
    min-height: 800px;
}

.right-col-wrapper {
    @include min-height-manager;
    .question-flow-sider {
        border-radius: 24px !important;
        width: 100%;
        min-height: 519px;
    }
}

.question-list__wrapper {
    @include min-height-manager;
}

.question-order__wrapper {
    @include min-height-manager;
}

.tab-title {
    display: flex;
    align-items: center;
}

.ant-tabs-content {
    background-color: $pure-white;
    padding: 24px !important;
}

.ant-tabs-nav {
    margin-bottom: 0px !important;
}

@include media('<=large') {
    .right-col-wrapper {
        margin-top: 24px;
        min-height: inherit !important;
        .question-flow-sider {
            position: static !important;
            top: inherit;
            transform: none;
        }
    }
}