@import '../../pages/include-media.scss';
@import '../../palette.scss';

.sidemenu {
    .logo-wrapper {
        position: relative;
        height: 50px;
        margin-bottom: 32px;
        .logo--full {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 130px;
            margin-top: 8px;
            display: block;
            cursor: pointer;
        }
        .icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 50px;
            height: 50px;
            margin-top: 8px;
            cursor: pointer;
            display: none;
        }
    }
    .ribbon {
        margin-right: 4px;
    }
    .ant-ribbon-corner {
        display: none;
    }
    &__links {
        .menu__actions {
            position: absolute;
            bottom: 2%;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;

            button {
                font-size: 0.9em;
                min-width: 82px;
            }

            .qr-code-btn__wrapper {
                background-color: $white;
                padding: 24px;
                display: flex;
                flex-direction: row;
                align-items: center;
                border-radius: 15px;
                margin: 4px 12px;
                height: 80%;

                &--vertical {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    line-height: 16px;

                    .card-text {
                        text-align: center;

                        &--blue {
                            color: $primary-action-color;
                        }
                    }
                }
            }
        }
    }
    &__multi-account {
        margin: 0px 8px;
    }
    &--small-button {
        button {
            font-size: .9em;
        }
    }
}

@include media('<desktop') {
    .sidemenu {
        .logo-wrapper {
            .logo--full {
                display: none;
            }
            .icon {
                display: block;
            }
        }
        .ribbon {
            display: none;
        }

        &__links{
            .menu__actions {
                display: none;
            }
        }
        &__multi-account {
            display: none;
        }
    }
}
