@import "./constants.scss";
@import "./palette.scss";
@import "./components/components.scss";
@import '../src/pages/include-media.scss';

html {
  // Just the content component should scroll
  overflow-y: hidden; 
  height: 100vh;
}

*:focus {
  outline: none;
}

.ant-layout {
  height: 100vh;
  min-height: calc(100vh - 64px);
  background-color: $background-color;
}

.ant-btn-primary {
  border: none !important;
}

.ant-menu-submenu-selected {
  color: $primary-action-color !important;
  .ant-menu-item-selected {
    color: $primary-action-color !important;
  }
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  color: rgba(0, 0, 0, 0.4) !important;
  background: $disabled-action-color !important;
  border-color: $disabled-action-border;
}

.ant-switch-checked { 
  background-color: $primary-action-color !important;
}

.ant-radio-button-wrapper-checked { 
  background-color: $primary-action-color !important;
  color: $pure-white !important;
}

body {
  min-height: 100%;
}

.App {
  height: 100%;
  min-height: 100vh;
}

.App-header {
    height: 100%;
    min-height: 100vh;
}

@include media('<tablet') {
  .App, .App-header {
    height: 100%;
    min-height: 100%;
  }
}

.text-error {
  color: $error;
}

#root {
  overflow: hidden;
  height: 100%;
}

.sider {
  overflow-y: auto;
  .ant-layout-sider-children {
    height: inherit;
    max-height: 100%;
  }
  .ant-menu-item-selected {
    background-color: transparent !important;
    &::after {
      border-right: 3px solid $primary-action-color !important;
    }
  }
}

.content {
  overflow-y: auto;
  height: 100;
  max-height: 100%;

  .routes-wrapper {
    padding: 32px 64px;
  }
}

.content > div {
  padding-bottom: 128px;
}


@include media('<tablet') {

  .routes-wrapper {
    padding: 32px 64px !important;
  }
}

.ant-menu {
  background-color: #f5f5f5 !important;
}

.ant-layout-header {
  min-height: 64px;
  height: auto;
  position: sticky;
  z-index: 1;
  top: 0px;
  padding: 0px;
  margin-top: 50px;
}

.opacity-5 {
  opacity: 0.5 !important;
}

.fade-in {
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: initial;
  }
}

.fade-out {
  animation: fadeout ease-out 2s;
}

@keyframes fadeout {
  0% {
    opacity: initial;
  }
  90% {
    opacity: 0;
  }
  100% {
    visibility: hidden;
  }
}

@keyframes pulseEffect {
  0% {
    filter: drop-shadow(0px 0px 0px rgba(102, 94, 255, 1));
  }
  50% {
    filter: drop-shadow(0px 0px 15px rgba(102, 94, 255, 1));
  }
  100% {
    filter: drop-shadow(0px 0px 0px rgba(102, 94, 255, 1));
  }
}

#tablet-input {
  height: 3.5rem;
}

#tablet-input::placeholder {
  color: rgb(194, 191, 251);
}

.hide {
  visibility: hidden;
}

.show {
  visibility: visible;
}

.remove {
  display: none !important;
}

.add {
  display: inherit !important;
}

.bold {
  font-weight: bold !important;
}

.padding-32 {
  padding: 32px !important;
}

.padding-24 {
  padding: 24px !important;
}

.padding-12 {
  padding: 12px !important;
}

.margin-32 {
  margin: 32px !important;
}

.margin-24 {
  margin: 24px !important;
}

.margin-12 {
  margin: 12px !important;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.h-align {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.v-align {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.v-margin {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.h-margin {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.table--white {
  tbody {
    background-color: $pure-white;
  }
}

.line-break {
  white-space: pre-line
}

.pointer {
  cursor: pointer;
}

.inline-block {
  display: inline-block;
}
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.overflow-y {
  overflow-y: auto;
}

.z-99 {
  z-index: 99;
}

.page-title {
  color: $page-title !important;
}

.break-word {
  word-break: break-word;
}

.all-white-wpaces-preserved {
  white-space: pre-wrap;
}

.responsive__button {
  white-space: normal;
  height: 100%;
}

.flag--small {
  width: 22px;
  border-radius: 55px;
}

.highlight-text {
  &--primary {
    padding: 8px !important;
    margin: 0px 48px !important;
    background: $background-color !important;
    color: $hightlight-text !important;
    border-radius: 15px;
  }
}

/*
  Hide google translate card
*/
#goog-gt-tt, .goog-te-balloon-frame{display: none !important;} 
.goog-text-highlight { background: none !important; box-shadow: none !important;}


/*

Small hack to make ant.design table work in a Tablet device (it still suck
on a smartphone). Please refer to: https://github.com/ant-design/ant-design/issues/11817#issuecomment-555699482

There is already a solution for it in the framework itself, but I could not make
it work properly. See here: https://ant.design/components/table/#components-table-demo-responsive

This topic should be revisited after the testing period.

*/

.ant-table {
  display: block;

  @media screen and (max-width: 768px) {
    &-thead {
      display: none;
    }

    &-thead > tr,
    &-tbody > tr {
      th,
      td {
        &:first-of-type {
          padding-top: 1rem;
        }

        &:last-of-type {
          padding-bottom: 1rem;
        }
      }

      > th,
      > td {
        display: block;
        width: auto !important;
        // border: none;
        // padding: 0 1rem;
        font-size: 1.1rem;

        &:last-child {
          border-bottom: 1px solid #eee;
        }
      }
    }
  }
}

/*
This .scss loop will create "margin helpers" and "padding helpers" for use in your web projects.
It will generate several classes such as:
.m-r-10 which gives margin-right 10 pixels.
.m-r-15 gives MARGIN to the RIGHT 15 pixels.
.m-t-15 gives MARGIN to the TOP 15 pixels and so on.
.p-b-5 gives PADDING to the BOTTOM of 5 pixels
.p-l-40 gives PADDING to the LEFT of 40 pixels
The first letter is "m" or "p" for MARGIN or PADDING
Second letter is "t", "b", "l", or "r" for TOP, BOTTOM, LEFT, or RIGHT
Third letter is the number of spacing in pixels. Adjust the amounts generated by editing the $spaceamounts variable below.
*/

$spaceamounts: (
  0,
  4,
  6,
  8,
  12,
  16,
  24,
  32,
  64,
  128,
); // Adjust this to include the pixel amounts you need.
$sides: (top, bottom, left, right); // Leave this variable alone

@each $space in $spaceamounts {
  @each $side in $sides {
    .m-#{str-slice($side, 0, 1)}-#{$space} {
      margin-#{$side}: #{$space}px !important;
    }

    .p-#{str-slice($side, 0, 1)}-#{$space} {
      padding-#{$side}: #{$space}px !important;
    }
  }
}

@mixin clamp($property, $min-size, $scaler, $max-size, $min-size-left:false, $scaler-left:false, $max-size-left:false){
  @if $min-size-left == false {
    #{$property}:$max-size !important; 
    #{$property}:#{"min(max(#{$min-size}, #{$scaler}), #{$max-size})"} !important; 
    #{$property}:clamp($min-size, $scaler, $max-size) !important;
  } @else if $min-size-left == 0 or $min-size-left == auto{
    #{$property}:$max-size $min-size-left !important; 
    #{$property}:#{"min(max(#{$min-size}, #{$scaler}), #{$max-size})"} $min-size-left !important;
    #{$property}:clamp($min-size, $scaler, $max-size) $min-size-left !important;
  } @else {
    #{$property}:$max-size $min-size-left !important; 
    #{$property}:#{"min(max(#{$min-size}, #{$scaler}), #{$max-size})"} #{"min(max(#{$min-size-left}, #{$scaler-left}), #{$max-size-left})"} !important; 
    #{$property}:clamp($min-size-left, $scaler-left, $max-size-left) !important;
  }
}
// TODO: add it to a proper file
.link-switch {
  margin-top: 16px !important;
  display: flex !important;
  gap: 16px;
  flex-direction: row-reverse !important;
  justify-content: flex-end !important;
  align-items: center !important;
  .ant-form-item-label {
    padding: 0 !important
  }
  .ant-form-item-control {
    flex-grow: 0 !important;
  }
}
