@import '../../../../../../palette.scss';


.allergens-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 2px;
    height: 100%;
    .allergen {
        flex: 1 1 50%;
        padding: 4px;
        .alergen-content {
            background-color: $allergens-bg;
            border-radius: 10px;
            text-align: center;
            height: 100%;
            padding: 4px;
            cursor: pointer;

            &.selected {
                background-color: $allergens-bg-selected;
                color: $allergens-color-selected;
            }

            div {
                position: relative;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}

.chilli-mobile {
    color: $error-soft !important;
    .ant-rate-star {
        &:not(.ant-rate-star-full) {
            .ant-rate-star-second {
                color: $soft-black;
                opacity: 0.5;
            }
        }
    }
}
