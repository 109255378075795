.subscriptions-wrapper {
  .subscriptions-header-image {
    position: relative;
    height: 400px;
    svg {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      height: 100%;
  }
  }
}