.guest-registration {
  .with-logo-wrapper {
    display: flex;
    flex-direction: column;

    .top-wrapper {
      flex: 0 1 auto;
    }

    .center-wrapper {
      flex: 1 1 auto;
      .question-flow-error__wrapper {
        text-align: center;
        .question-flow-error__image {
          width: 300px;
          opacity: 0.7;
          margin-bottom: 24px;
        }
      }
    }

    .bottom-wrapper {
      flex: 0 1 9vh;
    }
    .guest-registration__translation {
      width: 22px;
      position: absolute;
      right: 12px;
      top: 12px;
      z-index: 99;
    }
  }
}