@import '../../palette.scss';

.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $pure-white;
  padding: 32px;
  border-radius: 8px;

  h1 {
    margin-bottom: 0;
    span {
      display: block;
      font-size: 14px;
      font-weight: 400;
    }
  }
}