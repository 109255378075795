@import 'MultipleSelect/MultipleSelect.scss';
@import 'CapturePicture/CapturePicture.scss';
@import 'OutsideAlerter/OutsideAlerter.scss';
@import 'ThankYouPage/thankyou.scss';
@import 'GoodbyePage/goodbye.scss';
@import 'QuickCheckin/QuickCheckin.scss';
@import '../../constants.scss';
@import 'Disclaimer/disclaimer.scss';
@import 'Restaurant/restaurant.scss';
@import './Modal/modal.scss';
@import './Newsletter/newsletter.scss';
@import './GuestRegistration/guest-registration.scss';
@import './GoogleTranslator/google-translator.scss';
@import './Disclaimer/impressum.scss';
@import './CoverLoading/cover-loading.scss';
@import './Certifications/certification.scss';
@import './Disclaimer/data-protection.scss';
@import './AiChat/ai-chat.scss';

.textTablet {
    color: $primary-color !important;
    &--error{
        color: red !important;
    }
}

.inputTablet {
    &--error {
        border: solid red !important;
    }
}

@mixin inner_styles {
    .full-button {
        text-align: left;
        padding-left: 24px;
        width: 100%;
        border: 0px;
        background-color: transparent;
        border-radius: inherit;
        height: 50px;
        color: rgba(0, 0, 0, 0.85);
    }

    button{
        &.neumorphic-button {
            border: 0px;
            width: 100% !important;
            background-color: transparent;
            border-radius: inherit;
            height: 50px;
            color: rgba(0, 0, 0, 0.85);
        }
    }

    input[type="text"] {
        border: 0px;
        width: 100% !important;
        background-color: transparent;
        border-radius: inherit;
        padding-left: 12px;
        height: 50px;
        color: rgba(0, 0, 0, 0.85);
    }

    ul {
        list-style-type: none;
        margin: 24px 0px;
        width: 100%;
        max-height: 200px;
        overflow-y: auto;
        cursor: pointer;
        li {
            padding: 12px 0px;
        }
    }
}

.neumorphic {
    width: 100%;
    border-radius: 20px;
    background: $neumorphic-background-color;
    box-shadow:  -5px 5px 10px #e7e7ed, 
                 5px -5px 10px #ffffff;

    @include inner_styles;

    &__pressed {
        border-radius: 20px;
        background: $neumorphic-background-color;
        box-shadow: inset -5px 5px 10px #e7e7ed, 
                    inset 5px -5px 10px #ffffff;

        @include inner_styles;
    }

}

.clickWrapper {
    position: relative;
    width: 100% !important;
}

.index20 {
    position: absolute !important;
    top: 12px;
    z-index: 20;
}


.mt-4 {
    margin-top: 24px;
}
.mb-3 {
    margin-bottom: 18px;
}

.mb-4 {
    margin-bottom: 24px;
}

.form-dropdown-menu {
    height: 116px;
}