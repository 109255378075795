.navbar {
    padding: 0px 50px;
    background: transparent;
    transition: all linear 0.3s;
    &.header-scroll-bg {
        opacity: 0.9;
        background:  #5581C0;
        .club-name {
            color: #fff;
        }

        .club-logo {
            border: solid 2px #fff;
        }

        .language-selector {
            color: #fff;
        }
    }
    &:hover {
        opacity: 1;
    }
    .logout {
        position: relative;
        margin-right: 0px;
        margin-left: auto;
    }
    
    .right-side {
        float: right;
    }
    .club-logo {
        box-sizing: content-box;
        border: solid 2px #4E7FBF;
    }

    .right-side {
        display: flex;
    }

    .club-name {
        color: #4E7FBF;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .language-selector {
        font-size: 1.4rem;
        position: relative;
        align-self: center;
        color: #4E7FBF;
    }

    .logo {
        width: 160px;
        margin-top: 8px;
        cursor: pointer;
    }
}

@keyframes backgroundTransition {
    0% {
        background-color: inherit;
    }
    100% {
        background-color: $secundary-color;
    }
}
