.graph-wrapper-vertical {
  height: 100%;
  min-height: 488px !important;
  display: flex;
  align-items: center;
}

.graph-wrapper-horizontal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
}
