@import "../../../../../palette.scss";

.newsletter-wrapper {
  position: fixed;
  bottom: 0px;
  padding: 0px 6px 12px 6px;
  // animation: slideUp 2.5s ease forwards;
  .newsletter-body {
    width: 100%;
    background-color: $allergens-bg;
    border-radius: 15px;
    height: max-content;
    padding: 6px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.75);
    .dismiss-button {
      position: absolute;
      right: 12px;
      top: 4px;
    }
    .newsletter-title {
      padding: 0px;
      padding-left: 105px;
      max-width: 87%;
    }
    .newsletter-text {
      margin-top: 24px;
      padding-right: 6px;
      padding-left: 6px;
    }
    .newsetter-icon-wrapper {
      background-color: $allergens-bg;
      padding: 6px;
      position: absolute;
      height: 80px;
      border-radius: 50%;
      top: -27px;
      .newsetter-icon {
        background-color: $pure-white;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        svg {
          // width: 85%;
          // position: absolute;
          // left: 50%;
          // height: 100%;
          // transform: translate(-50%, -50%);
          // top: 50%;
          width: 110%;
          position: absolute;
          left: 62%;
          height: 100%;
          transform: translate(-50%, -50%);
          top: 39%;
          rotate: -15deg;
        }
      }
    }
  }
}

.newsletter-form-wrapper {
  padding: 24px;
  position: relative;
  height: 100%;
  .newsletter-bg {
    background-color: $pure-white;
    background: linear-gradient(8deg, $pure-white 0%, $public-base-background 50%, $pure-white 100%);
    height: 100%;
    border-radius: 25px;
    padding: 24px;
    .newsletter-logo-wrapper {
      height: 120px;
      width: 100%;
      position: relative;
      .newsletter-logo {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        height: 120px;
        width: 80%;
      }
    }
  }
}

.newsletter-ad {
  width: 100%;
  display: flex;
  &__content-wrapper {
    min-width: 100%;
    position: absolute;
    padding-left: 64px;
    left: 50%;
    transform: translateX(-50%);
  }
  img {
    max-width: 100%;
    border-radius: 25px;
    opacity: 0.75;
    min-width: 100%;
    min-height: 663px;
    height: calc(100vh - 94px);
    object-fit: cover;
  }
  &__text {
    position: absolute;
    bottom: 50px;
    padding: 0px 20px;
    .newsletter-ad__title {
      color: $pure-white !important;
      font-size: 2.5rem !important;
      font-weight: bolder;
      text-shadow: -3px 3px 10px #000000;
      margin-bottom: 8px;
      line-height: 2.8rem;
    }
    .newsletter-ad__subtitle {
      color: $pure-white !important;
      font-size: 1.2rem !important;
      font-weight: bolder;
      text-shadow: -3px 3px 10px #000000;
      margin: 0px;
    }
  }
}