.certification-icons {
  display: flex;
  position: absolute;
  padding: 0px 6px;
  opacity: 0.35;
  width: 100%;
  justify-content: center;
  img {
    max-width: 125px;
    margin: 0px 4px;
    width: 25%;
  }
}