@import '../../../../../palette.scss';

.ai-chat {
  &__wrapper {
    overflow-y: clip;
    height: 100%;
    .pdf-column {
      height: 100%;
    }
  }
  &__pdf {
    height: 100%;
    .pdf__wrapper {
      height: 100%;
      width: 100%;

      .pdf-load-error {
        margin: 24px;
        text-align: center;
        display: flex;
        flex-direction: column;
        height: 80vh;
        justify-content: center;
      }
    }
  }

  &__messages {
    padding: 12px;
    height: 85%;
    overflow-y: auto;
    background-color: $background-color;
  }
}

.conversation__wrapper {
  height: 100vh;
}

@mixin bubble-common {
  padding: 6px;
  margin: 6px;
  border-radius: 6px;
  flex: 0 1 auto;
  max-width: 75%;
  word-wrap: break-word;
}

.message-bubble {
  display: flex;
  flex-direction: column;
  &__server {
    @include bubble-common();
    background: $neumorphic-background-color;
    box-shadow:  -5px 5px 10px #e7e7ed, 
                 5px -5px 10px #ffffff;

    align-self: start;
  }

  &__user {
    @include bubble-common();
    background: $neumorphic-background-color;
    box-shadow:  -5px 5px 10px #e7e7ed, 
                 5px -5px 10px #ffffff;

    align-self: end;
  }

  .error-bubble {
    &__user {
      align-self: end;
      color: $error;
    }
    &__server {
      align-self: start;
      color: $error;
    }
  }
}

.ai-chat__input {
  background-color: $soft-black;
  min-height: 15%;
  padding: 12px;

  textarea {
    width: 100%;
    min-height: 100px;
    border: 0px;
    resize: none;
    border-radius: 6px;
    padding: 6px;
    background-color: $background-color;
  }

  .button__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    &--v-center {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
}

.drawer-button {
  display: none;
}

.chat-drawer {
  display: none;
}

.server-message__wrapper {
  display: flex;
  direction: row;
  
  .bubble-icon {
    width: 32px;
    flex: 0 0 32px;
  }

  .message-bubble {
    flex-grow: 4;
  }
}

@media (max-width: 992px) {
  body {
    overflow-y: hidden;
  }

  .tablet-wrapper {
    overflow-y: hidden ;
  }

  .chat-drawer {
      height: 100dvh;
      display: block;
  }

  .drawer-button {
    height: 8%;
    display: flex;
    width: 100%;
    position: fixed;
    bottom: 0px;
    background-color: $soft-black;
    flex-direction: row;
    align-items: center;
    padding: 0px 12px;
  }
  .ai-chat {
    &__wrapper {
      overflow-y: clip;
      height: 100dvh;
      .pdf-column {
        height: 100%;
      }
    }
  }
}

.chat-drawer {
  .ant-drawer-body {
    padding: 0 !important;
    height: 100%;
  }
  
  .ai-chat__messages--mobile {
    height: calc(100% - (15% + 24px) );
    overflow-y: auto;
  }

  .ai-chat__input--mobile {
    background-color: $soft-black;
    min-height: calc(15% + 24px);
    padding: 12px;

    textarea {
      width: 100%;
      height: 100%;
      border: 0px;
      resize: none;
      border-radius: 6px;
      padding: 6px;
      background-color: $background-color;
    }

    .button__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;

      &--v-center {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
  }
      
  }
}