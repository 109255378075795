.impressum__wrapper {
  padding: 12px;
  height: 100%;
  .impressum__container {
    .impressum__logo__wrapper {
      margin: 32px 0px;
      height: 150px;
    }
    .impressum__logo {
      height: 100%;
    }
    .impressum__body-header {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 12px;
      .impressum__title {
        margin-right: 12px;
        margin-bottom: 0px;
      }
    }
  }
}
