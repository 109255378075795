@import './constants.scss';

.tablet-wrapper {
  background: $neumorphic-background-color;
  position: relative;
  height: inherit;
  overflow-y: auto;
  .tablet-content-wrapper {
    width: 100%;
    height: 100%;
    .center-wrapper {
      padding: 0px 24px;
      .formwrapper {
        min-height: 396px;
      }
    }
    .lockscreen {
      position: relative !important;
      top: 100%;
      transform: translateY(-100%);
      padding: 0px 15%;
    }
    .center {
      color: $primary-color;
      font-family: Montserrat, sans-serif;
      font-size: 2.3rem;
      text-align: center;
    }
    .logo {
      position: relative;
      margin-top: 16px;
      width: 50%;
      left: 50%;
      transform: translateX(-50%);
      animation: pulseEffect 3.5s infinite;
      cursor: pointer;
      border-radius: 15%;
    }
    .observation {
      color: $primary-color;
      margin-top: 24px;
      font-family: Montserrat, sans-serif;
      font-size: 1.8rem;
      text-align: center;
    }
    .lockscreen-menu {
      position: absolute;
      bottom: 0px;
      width: 100%;
      .lockscreen-menu-text-wrapper {
        text-align: center;
        color: $primary-color;
        font-size: 1.3rem;
        font-weight: bold;
        clear: both;
        padding-top: 64px;
      }
      .lockscreen-menu-img-wrapper{
        height: 100px;
        img {
          width: 86px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          box-sizing:content-box;
          display: block;
        }
      
        canvas {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: block;
        }
      }
    }
    .bottom-wrapper {
      position: relative;
      height: 9vh;
      width: 100%;
      bottom: 0px;
      min-height: 9vh;
      max-height: 9vh;

      .tablet-form-disclaimer {
        position: absolute;
        width: 100%;
        // bottom: 2.5vh;
        text-align: center;
        font-size: 0.7rem;
        opacity: 0.4;
        padding: 0px 12px;

        .text--link {
          color: #0000EE;
          cursor: pointer;
        }
      }
    }
  }
}

@include media('>=phone', '<=desktop', 'landscape') {
  .tablet-wrapper {
    .tablet-content-wrapper {
      width: 100% !important;
      min-height: 100% !important;
    }
  }
}

@include media('<=350px') {
  .tablet-wrapper {
    .tablet-content-wrapper {
      .bottom-wrapper {
        min-height: 10vh;
        height: 10vh;
      }
    }
  }
}

@include media('>=phone', '<=desktop', 'landscape') {
  .tablet-wrapper {
    .tablet-content-wrapper {
      .center-wrapper {
        min-height: 35vw;
        .formwrapper {
          margin-bottom: 24px;
          .thank-you-page {
            max-height: unset;
          }
          .goodbye-page {
            max-height: unset;
          }
        }
      }
      .tablet-form-disclaimer {
          position: relative !important;
          top: 25% !important;
      }
    }
  }
}