@import '../../../../../palette.scss';

.mobile-modal-wrapper {
    position: fixed;
    background-color: rgba($color: #000000, $alpha: 0.3);
    min-height: 100vh;
    min-width: 100vw;
    top: 0px;
    left: 0px;
    z-index: 9999;

    .close-button {
        position: absolute;
        left: 99%;
        top: -24px;
        transform: translate(-50%, -50%);
        background-color: $soft-black;
        color: $pure-white;
        border-radius: 50px;
        width: 26px;
        cursor: pointer;
        z-index: 2;
        span {
            padding: 6px;
            padding-top: 7px;
        }
    }

    .mobile-modal-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 75vh;
        width: 100%;

        .mobile-modal-content {
            background-color: $white;
            padding-top: 24px;
            border-radius: 15px;
            height: 75vh;
            .full-height {
                padding: 12px;
                padding-top: 0px;
            }
        }

        .button-wrapper {
            position: relative;
            height: 100px;
            .button-close {
                position: absolute;
                left: 50%;
                bottom: 30%;
                transform: translateX(-50%);
                background-color: $soft-black;
                border: none;
                color: $white;
                border-radius: 15px;
                padding: 10px;
                cursor: pointer;
            }
        }

    }
}
