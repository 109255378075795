.c-datacharts {
    &__card {
        height: 450px !important;
        .ant-card-body {
            padding-top: 0px !important;
            height: 100%;
        }
    }

    &__canvas__wrapper {
        position: absolute;
        padding: 0px 24px;
        left: 50%;
        top: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
    }

    &__canvas__wrapper--pie {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 75%;
        transform: translate(-50%, -50%);
    }
}