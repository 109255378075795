.links-variations {
  .ant-radio-group {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
  }

  .ant-radio-button-wrapper {
    flex: 0 0 calc(33.3333% - 8px);
    padding-right: 0px;
  }
}