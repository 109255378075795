/* Coolors Exported Palette - coolors.co/5c415d-694966-74526c-8acdea-068d9d */

/* HSL */
$color1: hsla(298%, 18%, 31%, 1);
$color2: hsla(306%, 18%, 35%, 1);
$color3: hsla(314%, 17%, 39%, 1);
$color4: hsla(198%, 70%, 73%, 1);
$color5: hsla(186%, 93%, 32%, 1);

/* RGB */
$menu-color: rgba(105, 73, 102, 1);
$section-color: rgba(116, 82, 108, 1);
$primary-color: rgba(138, 205, 234, 1);
$secundary-color: rgba(6, 141, 157, 1);
$page-title: #4c566a;
$secundary-color-soft: #e7f5fa;
$white: RGBA(236, 239, 244, 1);
$pure-white: #fff;
$complementary-1: #f4f1ec;
$complementary-2: #f4edec;
$error: #ff0000;
$error-soft: #d81818;
$link: #0000EE;
$soft-black: #333;
$allergens-bg: #f8f8f8;
$allergens-bg-selected: #ffc7c7;
$allergens-color-selected: $error;
$neumorphic-background-color: #f8f8f8;

$background-color: #f1f1f1;
$welcomyhub-dragger-bg: #e9e9e9;
$welcomyhub-dragger-hamburger: #848484;
$hightlight-text: #555555;
$primary-action-color: #5581C0;
$disabled-action-color: #ebebeb;
$disabled-action-border: #d9d9d9;

$ai-chat-input-bg: #f8f8f8;
$ai-chat-user-bubble-bg: #f8f8f8;
$ai-chat-server-bubble-bg: #f8f8f8;
$ai-chat-conversation-bg: #f8f8f8;

/* PREMENU */
$v-card-bg: rgb(189, 189, 245);
$button-tag: rgb(200, 200, 243);
$button-tag-color: #f8f8f8;

$public-base-background: #f8f8f8;
