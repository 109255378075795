@import '../../../constants.scss';
@import '../../../content.scss';

.multiple-select {
    font-family: Montserrat, sans-serif;
    .list {
        border: none;
        overflow-y: auto;
        color: rgba(0, 0, 0, 0.85);
        .am-list-body {
            margin: 0px 0px;
            overflow-y: auto;
            background-color: transparent;
            width: 100%;

            &::before {
                content: none;
            }

            &::after {
                content: none;
            }
        }
    }
    .question {
        font-family: $question-font;
    }
}

.toggle-list {
    height: 100%;
    display: flex;
    // align-items: center;
    justify-content: space-evenly;
    overflow: hidden;
    font-weight: 300;


    .neumorphic-toggle-label {
        display: inline-flex;
        width: 100%;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .ant-row {
            width: 100%;
            padding-top: 12px;
        }
    }
    
    .neumorphic-toggle-text {
        margin-left: 16px;
    }
    
    .neumorphic-toggle {
        isolation: isolate;
        position: relative;
        min-height: 25px;
        min-width: 50px;
        height: 30px;
        width: 60px;
        border-radius: 15px;
        overflow: hidden;
        box-shadow:
        -8px -4px 8px 0px #ffffff,
        8px 4px 12px 0px #d1d9e6,
        4px 4px 4px 0px #d1d9e6 inset,
        -4px -4px 4px 0px #ffffff inset;
    }
    
    .toggle-state {
        display: none;
    }
    
    .toggle-indicator {
        height: 100%;
        width: 200%;
        background: $neumorphic-background-color;
        border-radius: 15px;
        transform: translate3d(-75%, 0, 0);
        transition: transform 0.4s cubic-bezier(0.85, 0.05, 0.18, 1.35);
        box-shadow:
        -8px -4px 8px 0px #e7e7ed,
        8px 4px 12px 0px #d1d9e6;
        
        .toggle-icon {
            display: inline-block;
            width: 50%;
            height: 100%;
            span {
                display: inline-block;
                width: 100%;
                height: 100%;
                svg {
                    height: 100%;
                    padding: 9px;
                    color: green;
                    display: inline-block;
                    margin: auto;
                    width: 100%;
                    position: relative;
                    left: 100%;
                    top:50%;
                    transform: translate(25%, -50%);
                }
            }
        }
    }
    
    .toggle-state:checked ~ .toggle-indicator {
        transform: translate3d(25%, 0, 0);
        .toggle-icon {
            display: inline-block;
            width: 50%;
            height: 100%;
            span {
                display: inline-block;
                width: 100%;
                height: 100%;
                svg {
                    height: 100%;
                    color: green;
                    display: inline-block;
                    margin: auto;
                    width: 100%;
                    position: relative;
                    left: 25%;
                    top:50%;
                    transform: translate(-50%, -50%);
                }
            }
        }
    }
}