@import '../../constants.scss';

.multiple-select {
    .list {
        background-color: rgba(255, 255, 255, 0);
        border: none;
        color: $primary-color;
        .am-list-body {
            margin: 24px 0px;
            background-color: transparent;
        }
    }
    .question-font {
        font-family: $question-font;
    }
}