/* 
 COMPONENTS
*/
@import 'components/components.scss';
@import 'public/components/components.scss';
@import './content.scss';
@import './header.scss';
@import '../include-media.scss';



@mixin clamp($property, $min-size, $scaler, $max-size, $min-size-left:false, $scaler-left:false, $max-size-left:false){
    @if $min-size-left == false {
      #{$property}:$max-size !important; 
      #{$property}:#{"min(max(#{$min-size}, #{$scaler}), #{$max-size})"} !important; 
      #{$property}:clamp($min-size, $scaler, $max-size) !important;
    } @else if $min-size-left == 0 or $min-size-left == auto{
      #{$property}:$max-size $min-size-left !important; 
      #{$property}:#{"min(max(#{$min-size}, #{$scaler}), #{$max-size})"} $min-size-left !important;
      #{$property}:clamp($min-size, $scaler, $max-size) $min-size-left !important;
    } @else {
      #{$property}:$max-size $min-size-left !important; 
      #{$property}:#{"min(max(#{$min-size}, #{$scaler}), #{$max-size})"} #{"min(max(#{$min-size-left}, #{$scaler-left}), #{$max-size-left})"} !important; 
      #{$property}:clamp($min-size-left, $scaler-left, $max-size-left) !important;
    }
  }


@include media('<tablet') {
    h1.clamp-text {
        @include clamp(font-size, 25px, 5vw, 50px);
        // font-size: 8vw !important;
        // font-size: clamp(150px, 8vw, 350px) !important;
        overflow-wrap: normal;
    }
}


.with-logo-wrapper {
    height: 100%;
    min-height: 100vh;
    .top-wrapper {
      position: relative;
      height: 30vh;
      min-height: 243px;
      padding: 0px 24px;
      .logo-public {
          position: absolute;
          min-width: 55%;
          height: 50%;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          top: 2.5vh;
          left: 50%;
          transform: translateX(-50%);
          // opacity: 0.4;
          z-index: 1;
      }
    }
}
