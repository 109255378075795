body > .skiptranslate {
  display: none;
}

.goog-te-banner-frame.skiptranslate {
  display: none !important;
}

.goog-te-gadget{
  display: none;
}

.goog-te-combo {
  display: none;
}

body {
  top: 0px !important;
}

.google-translator__dropdown {
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}

@media print {
  #google_translate_element {
    display: none;
  }
}