@import "../../../../../palette.scss";
@import "../../../../include-media.scss";


@include media('>=phone', '<=desktop', 'landscape') {
  .capture-wrapper {
    height: 100vw !important;
    width: 100vh !important;
    video, canvas {
      width: 100% !important;
      height: 80vw !important;
    }
  }
  .capture-menu {
    height: 20vw !important;
  }
}
@mixin button-size-phone($type) {
  @if $type == "camera" {
    width: 80px !important;
    height: 80px !important;
  }
  @if $type == "back" {
    width: 50px !important;
    height: 50px !important;
  }
}

@mixin icons-size-phone {
  font-size: 1.5rem !important;
}

.capture-wrapper {
  position: absolute;
  z-index: 99999 !important;
  height: 100vh ;
  left: 0px !important;
  transform: translateY(-50%);
  width: 100vw ;
  video,
  canvas {
    object-fit: cover;
    width: 100%;
    height: 80vh;
  }
  .camera-back-button {
    margin-top: 5%;
    margin-left: 5%;
    position: absolute;
    width: 100px;
    height: 100px;
    border-radius: 50% !important;
    z-index: 99 !important;
    button {
      background-color: rgba(0, 0, 0, 0.5);
      border: none;
      border-radius: inherit;
      width: 100%;
      height: 100%;
      svg {
        color: $white;
        font-size: 2rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.capture-menu {
  position: relative;
  height: 20vh;
  .red {
    color: #ff4d4f;
  }
  .green {
    color: #ff4d4f;
  }

  div {
    &.neumorphic {
      position: absolute;
      top: 50% !important;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100px;
      height: 100px;
      border-radius: 50% !important;
    }
    &.back {
      z-index: 999;
    }
    &.check {
      transform: translate(25%, -50%);
    }
    &.close {
      transform: translate(-125%, -50%);
    }
    button {
      &.neumorphic-button {
        height: 100%;
        span {
          position: relative;
          &.camera-icon {
            height: 100%;
            font-size: 2rem;
            svg {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }
  }
}

@mixin mobile-style {
  .capture-wrapper {
    .camera-back-button {
      @include button-size-phone('back');
      button {
        svg {
          @include icons-size-phone;
        }
      }
    }
  }

  .capture-menu {
    div {
      &.neumorphic {
        @include button-size-phone('camera');
      }
      button {
        &.neumorphic-button {
          span {
            &.camera-icon {
              @include icons-size-phone;
            }
          }
        }
      }
    }
  }
}

@include media(">=phone", "<=tablet") {
  @include mobile-style;
}


@include media(">=phone", "<=desktop", 'landscape') {
  @include mobile-style;
}

.capture-picture {
  font-family: "Raleway, sans-serif";
  text-align: center;
  padding: 24px 0px;
  width: 100%;
  .title {
    margin-top: 0px;
  }
  video {
    object-fit: cover;
    width: 100%;
    height: 600px;
    border-radius: 5%;
    border: 8px solid $white;
  }
  canvas {
    object-fit: cover;
    width: 100%;
    height: 600px;
    border-radius: 5%;
    border: 8px solid $white;
  }
  .captured-buttons {
    .am-flexbox-item {
      padding: 6px;
    }
  }
}
