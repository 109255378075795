@import "../../palette.scss";
@import "../../App.scss";

.visitor-log-wrapper {
    .ant-list {
        &.ant-list-split {
            margin-top: 12px;
            border-radius: 15px;
            background-color: $secundary-color-soft !important;
        }
    }

    .showField {
        font-size: 1.15rem;
        color: $secundary-color;
        float: right;
    }

    .widgets {
        height: 100%;
        min-height: 200px;

        &.card-has-emergency {
            background-color: $error-soft;
            color: $white;
            &.card-has-footer {
                .ant-card-actions {
                    background-color: $error-soft;
                }
            }
            .widget--text {
                &:before {
                    height: 120% !important;
                    transform: translateY(-10%);
                    border-color: $white !important;
                }
            }
        }

        &.card-has-footer {
            & > div {
                height: 77% !important;
            }

            .widget--content {
                top: 40%;
            }

            .widget--text {
                &:before {
                    height: 120% !important;
                    transform: translateY(-10%);
                }
            }

            .widget--text--error {
                font-weight: bolder;
                @include clamp(font-size, 18px, 2vw, 1.2rem);
                text-align: left;
                margin-left: 32px;
                display: inline-block;
            }

            .ant-card-actions {
                border: none;
                background-color: $primary-action-color;
                .widget-single-action {
                    text-align: right;
                    margin-right: 24px;
                    color: $white;
                    font-weight: 900;
                }
            }
        }

        .widget--content {
            position: absolute;
            width: 80%;
            left: 50%;
            height: 100%;
            top: 50%;
            transform: translate(-50%, -50%);
            
            .ant-divider-vertical {
                height: 5em;
            }

            .widget--number {
                font-weight: bolder;
                font-size: 2.5rem;
                text-align: center;
                word-break: break-all;
                line-height: normal;
            }

            .widget--text {
                font-weight: bolder;
                @include clamp(font-size, 18px, 2vw, 1.2rem);
                text-align: left;
                margin-left: 32px;
                display: inline-block;

                &:before {
                    border-left: 1px solid #000;
                    height: 100%;
                    left: 16px;
                    position: absolute;
                    display: inline-block;
                    content: "";
                }
            }
        }
    }
}