.premenu {
  display: flex;
  flex-direction: column;

  &--fix-flex {
    height: auto !important;
  }

  &__translation {
    width: 22px;
    position: absolute;
    right: 12px;
    top: 12px;
  }

  &__certifications {
    position: relative;
    height: 47px;
  }

  &__social-icons {
    border: none;
    background-color: transparent;
    font-size: 20px;
    color: rgba(0,0,0,0.60);
  }

  &__logo-wrapper {
    position: relative;
    height: 50%;
    min-width: 55%;
    margin-top: 2.5vh;
    min-height: 150px;

    .logo-public {
      height: 75% !important;
      top: 0 !important;
    }
  }

  .premenu-title__wrapper {
    position: relative;
    .premenu-title {
      display: flex;
      flex-direction: column;
      width: 100%;
      
      &__description__wrapper {
        display: flex;
        justify-content: center;
      }
  
      &__head-description {
        width: 100%;
        max-width: 350px;
      }
    }
  }

  &--fix-flex-top {
    flex: 0 1 auto;
  }

  &--fix-flex-bottom {
    flex: 0 1 9vh;
    .bottom-wrapper {
      .tablet-form-disclaimer {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  &--expand-flex {
    flex: 1 1 auto;
  }
}

.download-vcard {
  width: 50%;
  max-width: 240px;
  margin: 0 auto;

  button {
    background: $v-card-bg !important;
    color: $button-tag-color !important;
    cursor: pointer;
    height: auto !important;
    padding: 8px;
  }
}

@keyframes shine {
  0% {
    background-position: right;    
  }
}