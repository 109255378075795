@import '../../../../../palette.scss';
@import '../../../constants.scss';

.overlay-backdrop {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0 ,0, 0, 0.7);
    z-index: 1000;

    .quick-checkin-wrapper {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: #f8f8ff;
        width: 80%;
        height: 80%;
        // min-height: 500px;
        border-radius: 25px;

        & > .quick-checkin-content {
            text-align: center;
            width: 100% !important;
            height: 100% !important;
            overflow-x: hidden;
            padding: 32px;

            & > .icon {
                font-size: 20vw;
                color: $secundary-color;
            }

            & > .quick-checkin-title {
                padding-top: 24px;
                font-family: $question-font;
                font-size: 1.4rem !important;
            }

            & > .quick-checkin-text {
                font-size: 0.8rem !important;
            }

            & > .quick-checkin-buttons {
                padding-top: 12px;

                .neumorphic-button__wrapper {
                    padding-top: 24px;
                }
            }
        }
    }
}