@import '../../palette.scss';

.editor-modal {
    height: 80%;
}

.add-button {
    padding: 16px !important;
    height: auto !important;
    font-size: 16px !important;
}

.welcomyhub-wrapper {
    .edit-link-card {
        border-top: 0;
        position: relative;
        border-left: 1px solid $primary-action-color!important;
        top: -10px;
        border-radius: 0;
        &:hover {
            box-shadow: none;
        }
    }
    .card {
        border-left: 1px solid $primary-action-color!important;

        &.disable {
            opacity: 0.7;
            pointer-events: none;
            filter: blur(2px)
        }
        &:hover {
            box-shadow: 0 3px 8px rgba(0,0,0,0.1) !important;
        }

        .meta {
            margin-left: 20px;
        }

        .card-actions {
            position: absolute;
            right: 12px;
            top: 50%;
            transform: translateY(-50%);

            .card-action-button {
                margin: 6px 0px;
                font-size: 1.1rem;
            }
        }

        .card-dragger-wrapper {
            position: absolute;
            left: 0px;
            top: 0px;
            height: 100%;
            width: 36px;

            .card-dragger {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: $welcomyhub-dragger-hamburger;
                margin: 6px 0px;
                font-size: 1rem;
                cursor: grab;
                opacity: 0.3;
            }
        }
    }
}