@import '../../../constants.scss';
.thank-you-page {
    text-align: center;
    max-height: 50vh;
    .header {
        font-family: $question-font;
    }
    .marker {
        width: 4.5vh;
        opacity: 0.7;
        ._marker {
            animation: hover 1.3s ease-in-out infinite alternate;
        }
    }
}

@keyframes hover {
    from {
        transform: translate3d(0,0,0);
    }
    to {
        transform: translate3d(0,6px,0);
    }
}