@import "../../palette.scss";
@import "../../constants.scss";

.login-wrapper {
    height: 100vh;
    width: 100vw;
    min-height: 100%;
    background-color: $background-color;
    .ant-row {
        height: inherit;
    }

    & > div > .login-right {
        height: 100%;
        // background: linear-gradient(0deg, rgba(255,237,190,1) 0%, rgba(149,194,255,1) 100%);
        background: linear-gradient(180deg, rgba(255,237,190,1) 0%, rgba(149,194,255,1) 100%);
        display: flex;
        justify-content: center;

        .welcomy-screenshot {
            display: inline-block;
            width: 100%;
            img {
                width: 85%;
                height: auto;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }
}

.error-message {
    color: $error;
    margin: 8px;
    display: block;
}

.left-side-wrapper {
    position: relative;
    z-index: 10;
    height: 100%;
    padding: 24px;
}

.login-svg-wrapper {
    width: 100%;
    height: 100%;
    padding-top: 48%;
    position: relative;
}

.svg-image { 
    position: absolute;
    top: 0;
    left: 0;
}

.login-logo {
    svg {
        width: 150px;
    }
}

.form-wrapper {
    font-family: $body-font !important;
    position: absolute;
    top: 50%;
    width: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0px 10%;

    .client-logo-wrapper {
        .logo {
            max-width: 150px;
            margin-bottom: 24px;
        }
    }

    h2, h4 {
        margin: 0.5rem;
        margin-top: 0px !important;
    }

    .form-inline {
        input {
            display: inline-block !important;
            width: 65% !important;
            text-align: right;
        }
        label {
            margin-left: 12px;
            font-weight: bolder;
            font-size: 1rem;
        }
    }

    .login-button {
        margin-top: 12px;
        color: $white;
        border-color: $primary-action-color;
        background-color: $primary-action-color;
    }
    
    .login-button--ghost {
        margin-top: 12px;
        margin-right: 12px;
        color: $primary-action-color;
        border-color: $primary-action-color;
    }

    .welcomy-input {
        background-color: transparent !important;
        border: 1px solid;
        border-color: $primary-action-color !important;
        border-radius: 16px;
        padding: 10px;
        margin: 6px 0px;
        width: 100%;

        .ant-input {
            background-color: transparent !important;
        }
    }
}

.login-logo-small {
    display: none;
}

.login-navigation {
    position: absolute;
    z-index: 10;
    padding-top: 40px;
    right: 3.5rem;

    ul {
        display: flex;
    }

    li {
        font-size: 1.5rem;
        margin-left: 2.5rem;
        list-style-type: none;
        cursor: pointer;

        a {
            text-decoration: none;
        }
    }
}
 /* tablet, landscape iPad, lo-res laptops ands desktops */
@media (max-width:1024px)  {
    .svg-image {
        position: abosolute;
        opacity: 0.5;
        width: 100%;
    }
    .form-wrapper {
        .form-inline {
            input {
                display: block !important;
                width: 100% !important;
                text-align: center;
            }
            label {
                display: none;
            }
        }
    }
    
}

@media (max-width:641px) {
    .client-logo-wrapper {
        display: flex;
        justify-content: center;
    }

    .login-wrapper {
        overflow-y: auto;
    }

    .login-logo {
        display: none;
    }
    .login-logo-small {
        display: none;
        svg {
            width: 250px;
            padding-bottom: 16px;
        }
    }
    .form-wrapper {
        .form-inline {
            input {
                display: block !important;
                width: 100% !important;
                text-align: center;
            }
            label {
                display: none;
            }
        }
    }
    .form-wrapper {
        transform: translate(-50%, -50%);
    }
}
