.max-width-1200 {
  max-width: 1200px;
}
.workspace-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  padding: 32px;
  border-radius: 8px;

  h1 {
    margin-bottom: 0;
    span {
      display: block;
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.workspace-wrapper {
  .ant-space {
    width: 100%;
    justify-content: right;
  }
  .workspace-search {
    width: 250px;
    margin: 12px 0;
  }
}
