.viewer {
  background-color: #fff;
  padding: 32px !important;
  max-height: 100vh;
  overflow-y: scroll;
  border-radius: 25px !important;
  display: none;
}

.pdf {
  width: 100%;
  height: max-content;
}
.category__dish > .dish__table {
  width: 100%;
}

.table__title-description {
  width: 70%;
  vertical-align: top;
  padding-right: 12px;
  white-space: pre-line;
}
.table__prices {
  width: 30%;
  vertical-align: middle;
}

.dish__prices {
  width: 100%;
  border-collapse: separate;
  border-spacing: 6px;
}

.price__name {
  width: 50%;
  word-wrap: break-word;
  text-align: right;
  padding-right: 6px;
}
.price__value {
  padding-left: 6px;
  width: 50%;
  word-wrap: break-word;
  border-left: 1px solid $soft-black;
}

.pdf--avoid-break {
  page-break-inside: avoid !important;
}

.pdf__header {
  text-align: center;
}

.header__logo > img {
  width: 25%;
}

.header__name {
  margin: 24px 0px 0px 0px;
}

.header__subtitle {
  margin: 0px 0px;
  margin-bottom: 24px;
}

.allergens--sup {
  font-size: 7pt;
  top: -7pt;
  left: 2pt;
}

.allergens__footer {
  width: 100%;
  page-break-before: always !important;
  position: relative;
}

.allergens__wrapper {
  position: absolute;
  height: 100%;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.allergens__title {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
}

.allergen {
  display: flex;
  flex-direction: column;
  flex: 1 1 50%;
}

.ant-checkbox-group-item {
  display: block;
  margin-right: 0;
}
