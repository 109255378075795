@font-face {
  font-family: 'Roboto';
  src: url("./static/Roboto.woff2") format("woff2");
  font-display: "swap"
}

@font-face {
  font-family: 'Raleway';
  src: url("./static/Raleway.woff2") format("woff2");
  font-display: "swap"
}

@import './palette.scss';

@import './pages/pages.scss';

/*
  TABLET STYLES
*/
@import "pages/Tablet/tablet.scss";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

.ant-upload.ant-upload-drag {
  background-color: #e5e9f0 !important;
}

.ant-upload-drag-icon > i {
  color: #434c5e !important;
}

.ant-upload-hint {
  color: #4c566a !important;
}

.ant-upload-drag-container > p.ant-upload-text {
  color: #2e3440 !important;
}