@import '../../palette.scss';

.emergency-wrapper {
    overflow-y: auto;
    height: 100%;
    padding-left: 32px;
    padding-right: 32px;
    .logo {
        max-width: 200px;
    }
    .language-selector {
        svg {
            font-size: 1.4rem;
            color: $secundary-color;
        }
    }
}