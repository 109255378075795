@import "../../palette.scss";

.digital-menu-wrapper {
  .product-image-wrapper {
    height: 350px;
  }
  .main-image {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0.7;
    &--gastro {
      width: 50%;
      max-width: 350px;
    }
    &--product {
      height: 100%;
    }
  }

  .selection {
    background-color: $pure-white;
    padding: 24px 12px;
    border-radius: 12px;
    .text {
      font-weight: bolder;
      font-size: 1rem;
    }
    .dropdown {
      width: 120px;
    }
  }

    .button--new-category {
      float: right;
    }
    .category-creation-wrapper {
      .category-creation-input {
        border-bottom: solid 1px #333;
        border-radius: 0px;
        font-size: 1.5rem;
      }
    }

  .create-dish-wrapper {
    .chk-group {
      line-height: 32px;
    }
  }

  .categories__shortcut {
    position: absolute;
    top: 764px;
    padding: 0px 24px;
    height: calc(100% - 764px);
    width: 100%;
    &--sticky-wrapper {
      position: sticky;
      top: 72px;
      max-height: 800px;
      overflow: auto;

      .categories__titles {
        border-left: 2px solid $primary-action-color;
        padding-left: 12px;
        cursor: pointer;
      }
    }
  }
}

#components-table-demo-drag-sorting tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

#components-table-demo-drag-sorting tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}

.max-width-100 {
  max-width: 100px;
}

.chilli {
  color: $error-soft;
}