.cover-loading {
  position: fixed;
  min-height: 100vh;
  height: 100%;
  width: 100vw;
  background: $neumorphic-background-color;
  z-index: 99;
  .cover-loading__logo {
    height: 100%;
    padding: 32px;
    .cover-loading__icons {
      // position: relative;
      // width: 100%;
      // height: 100%;
      // display: flex;
      // flex-direction: column;
      // justify-content: end;
      position: fixed;
      width: 100vw;
      top: 90%;
      left: 0px;
    }
    .cover-loading__wrapper {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 50%;
      width: 55%;
      max-width: 350px;
      .logo-public {
        height: 100%;
        width: 100%;
        animation: loading 3s infinite ease-in-out;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

}

@keyframes loading {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
