@import '../../../../palette.scss';

.capture-wrapper {
    height: 100vh;
}

.capture-picture {
    font-family: "Raleway, sans-serif";
    text-align: center;
    padding: 24px 0px;
    width: 100%;
    .title {
        margin-top: 0px;
    }
    video {
        object-fit: cover;
        width: 100%;
        height: 600px;
        border-radius: 5%;
        border: 8px solid $white;
    }
    canvas {
        object-fit: cover;
        width: 100%;
        height: 600px;
        border-radius: 5%;
        border: 8px solid $white;
    }
    .captured-buttons {
        .am-flexbox-item {
            padding: 6px;
        }
    }
}